import './Login.css'
import LoginForm from "../../components/LoginForm/LoginForm";
// import { useNavigate } from "react-router-dom";
// import { useEffect } from 'react';

export default function Login({ updateClient, client }) {
  // const navigate = useNavigate()

  // useEffect(() => {
  //   if (user) {
  //     navigate('/')
  //   }
  //   // eslint-disable-next-line
  // },[])
  return (
    <div className="Login">
      <div className='formContainer'>
        <LoginForm updateClient={updateClient}/>
      </div>
    </ div>
  );
}