import './App.css';

import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

// Helpers
import { getUser } from '../../utilities/services/clients';

// Components
import Login from '../Login/Login';
import NavBar from '../../components/NavBar/NavBar';
import IntakeForm from '../IntakeForm/IntakeForm';
import { getServices } from '../../utilities/api/services';
import FormList from '../FormList/FormList';

export default function App() {
  const [client, setCLient] = useState(getUser());
  const [currentPage, setCurrentPage] = useState('FormList');
  const [clientServices, setClientServices] = useState(null);

  const updateClient = (client) => {
    setCLient(client)
  }

  const getCurrentClientServices = async (client) => {
    const services = await getServices()
    if (!client) return
    const currentClientServices = services.filter(service => client.clientServices.includes(service.code))
    setClientServices(currentClientServices)
  }


  const updateCurrentPage = (page) => {
    setCurrentPage(page)
  }

  return (
    <main className="App">
      {
        !client ?
        <main className='body login'>
          <Routes>
            <Route path="/*" element={<Login updateClient={updateClient} client={client} />} />
          </Routes>
        </main>
        :
        <main className='body'>
          <div className='navbar'>
            <NavBar client={client} updateClient={updateClient} currentPage={currentPage} />
          </div>
          <div className='pages'>
            <Routes>
              <Route path="/" element={<FormList client={client} updateCurrentPage={updateCurrentPage} getCurrentClientServices={getCurrentClientServices} clientServices={clientServices} />} />
              <Route path='/intakeForms/:formID' element={<IntakeForm client={client} updateCurrentPage={updateCurrentPage} />} />
              <Route path='/*' element={<FormList client={client} updateCurrentPage={updateCurrentPage} getCurrentClientServices={getCurrentClientServices} clientServices={clientServices} />} />
            </Routes>
          </div>
        </main>
      }
    </main>
  );
}
