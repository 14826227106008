import { useNavigate } from 'react-router-dom'
import { updateIntakeForm } from '../../utilities/api/clients'
import './FormListItem.css'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter'

export default function FormListItem({ client, form, updateIntakeForms }) {
    const navigate = useNavigate()

    const startNewIntakeForm = async (evt) => {
        // console.log(form)
        await updateIntakeForm(client._id, form._id, {
            service: form.service,
            status: 'inProgress'
        })
        await updateIntakeForms(client._id)
        navigate(`intakeForms/${form._id}`)
    }

    const continueIntakeForm = () => {
        // console.log(form)
        navigate(`intakeForms/${form._id}`)
    }

    const viewIntakeForm = () => {
        // console.log(form)
        navigate(`intakeForms/${form._id}`)
    }

  return (
    <div className='FormListItem'>
        <div className='section1'>
            <div className={`formStatus ${form.status}`}>
                { form.status === 'new' &&
                    <span className={`statusColor ${form.status}`}></span>
                }
                { ['inProgress', 'submitted', 'changesApproved'].includes(form.status) &&
                <svg width="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 0.96875C4.78854 0.96875 0.96875 4.78854 0.96875 9.5C0.96875 14.2115 4.78854 18.0312 9.5 18.0312C14.2115 18.0312 18.0312 14.2115 18.0312 9.5C18.0312 4.78854 14.2115 0.96875 9.5 0.96875ZM13.4375 10.8125H9.5C9.32595 10.8125 9.15903 10.7434 9.03596 10.6203C8.91289 10.4972 8.84375 10.3303 8.84375 10.1562V4.25C8.84375 4.07595 8.91289 3.90903 9.03596 3.78596C9.15903 3.66289 9.32595 3.59375 9.5 3.59375C9.67405 3.59375 9.84097 3.66289 9.96404 3.78596C10.0871 3.90903 10.1562 4.07595 10.1562 4.25V9.5H13.4375C13.6115 9.5 13.7785 9.56914 13.9015 9.69221C14.0246 9.81528 14.0938 9.9822 14.0938 10.1562C14.0938 10.3303 14.0246 10.4972 13.9015 10.6203C13.7785 10.7434 13.6115 10.8125 13.4375 10.8125Z" fill="#DFB200"/>
                </svg>                
                }
                { ['changesSubmitted'].includes(form.status) &&
                <svg width="17" fill="#E21D12" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.311 45.311">
                    <path d="M22.675,0.02c-0.006,0-0.014,0.001-0.02,0.001c-0.007,0-0.013-0.001-0.02-0.001C10.135,0.02,0,10.154,0,22.656c0,12.5,10.135,22.635,22.635,22.635c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c12.5,0,22.635-10.135,22.635-22.635C45.311,10.154,35.176,0.02,22.675,0.02z M22.675,38.811c-0.006,0-0.014-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001c-2.046,0-3.705-1.658-3.705-3.705c0-2.045,1.659-3.703,3.705-3.703c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c2.045,0,3.706,1.658,3.706,3.703C26.381,37.152,24.723,38.811,22.675,38.811z M27.988,10.578c-0.242,3.697-1.932,14.692-1.932,14.692c0,1.854-1.519,3.356-3.373,3.356c-0.01,0-0.02,0-0.029,0c-0.009,0-0.02,0-0.029,0c-1.853,0-3.372-1.504-3.372-3.356c0,0-1.689-10.995-1.931-14.692C17.202,8.727,18.62,5.29,22.626,5.29c0.01,0,0.02,0.001,0.029,0.001c0.009,0,0.019-0.001,0.029-0.001C26.689,5.29,28.109,8.727,27.988,10.578z"/>
                </svg>
                }
                { ['complete'].includes(form.status) &&
                <svg width="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 1.96875C5.79592 1.96875 1.96875 5.79592 1.96875 10.5C1.96875 15.2041 5.79592 19.0312 10.5 19.0312C15.2041 19.0312 19.0312 15.2041 19.0312 10.5C19.0312 5.79592 15.2041 1.96875 10.5 1.96875ZM14.9399 7.6408L9.42744 14.2033C9.36697 14.2753 9.29173 14.3335 9.20681 14.3739C9.12189 14.4143 9.02928 14.436 8.93525 14.4375H8.92418C8.8322 14.4375 8.74125 14.4181 8.65723 14.3806C8.57322 14.3432 8.49802 14.2885 8.4365 14.2201L6.074 11.5951C6.014 11.5315 5.96733 11.4565 5.93673 11.3746C5.90612 11.2926 5.89221 11.2054 5.89579 11.118C5.89938 11.0306 5.9204 10.9448 5.95762 10.8657C5.99483 10.7865 6.04749 10.7156 6.11251 10.6571C6.17752 10.5986 6.25357 10.5537 6.33619 10.525C6.41881 10.4963 6.50634 10.4844 6.59362 10.49C6.6809 10.4956 6.76618 10.5187 6.84444 10.5577C6.9227 10.5967 6.99237 10.651 7.04936 10.7174L8.90695 12.7813L13.9351 6.7967C14.0478 6.6663 14.2074 6.58553 14.3793 6.57183C14.5511 6.55814 14.7215 6.61262 14.8535 6.72351C14.9855 6.8344 15.0686 6.99279 15.0848 7.16444C15.1009 7.33608 15.0489 7.5072 14.9399 7.6408Z" fill="#158444"/>
                </svg>                    
                }
                <span className='statusText'>
                {form.status === 'new' ? 'New' : form.status === 'inProgress' ? 'In Progress' : form.status === 'submitted' ? 'Submitted' : form.status === 'complete' ? 'Complete' : form.status === 'changesSubmitted' ? 'Changes Made' : form.status === 'changesApproved' ? 'Changes Approved' : ''}
                </span>
            </div>
            <div className='creationDate'>
                {form.createdAt.split('T')[0]}
            </div>
        </div>
        <div className='projectNumber'>{form.projectNumber}</div>
        { (form.titleOfInvention || form.title) ?
        <div className='titleOfInvention'><small></small>{capitalizeFirstLetter(form.titleOfInvention || form.title)}</div>
        :
        (form.service === 'IPSA' || form.service === 'LA') ?
        <div className='titleOfInvention'>{form.service}</div>
        :
        <div className='titleOfInvention'><small><i>No title</i></small></div>
        }
        <div className='formButtonContainer'>
            { (form.status === 'new') &&
            <button className='new' onClick={startNewIntakeForm}>
                Start&nbsp;&nbsp;&nbsp;
                <svg width="14" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.56 1L15.04 7.5L8.56 14M14.14 7.5H1" stroke="currentColor" strokeOpacity="0.79" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            }
            { ['inProgress', 'changesSubmitted'].includes(form.status) &&
            <button className='inProgress' onClick={continueIntakeForm}>
                { form.status === 'inProgress' ? 'Continue' : 'Review Changes'}
                &nbsp;&nbsp;&nbsp;
                <svg width="14" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.56 1L15.04 7.5L8.56 14M14.14 7.5H1" stroke="currentColor" strokeOpacity="0.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            }
            { ['submitted', 'complete', 'changesApproved'].includes(form.status) &&
            <button className='submitted' onClick={viewIntakeForm}>
                View&nbsp;&nbsp;&nbsp;
                <svg width="14" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.56 1L15.04 7.5L8.56 14M14.14 7.5H1" stroke="currentColor" strokeOpacity="1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            }
        </div>
        
    </div>
  )
}
