import { useEffect, useState } from 'react'
import './FormList.css'
import { getIntakeForms } from '../../utilities/api/clients'
import { getServices } from '../../utilities/api/services'
import FormListItem from '../../components/FormListItem/FormListItem'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter'

export default function FormList({ client, updateCurrentPage}) {
    const [services, setServices] = useState(null)
    const [loading, setLoading] = useState(true)
    const [existingClientServices, setExistingClientServices] = useState(null)
    const [existingIntakeForms, setExistingIntakeForms] = useState(null)

    const updateIntakeForms = async (id) => {
        setLoading(true)
        try {
            const forms = await getIntakeForms(id)
            forms.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
            setExistingIntakeForms(forms)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const getAllServicesSorted = async () => {
        try {
            const serviceList = await getServices()
            serviceList.sort((a, b) => a.name < b.name ? -1 : 1)
            setServices(serviceList)
        } catch (error) {
            console.log(error)
        }
    }

    const getExistingClientServices = () => {
        const existingServices = []
        if (existingIntakeForms && services) {
            existingIntakeForms.forEach(form => {
                existingServices.push(form.service)
            })
            const uniqueServices = [...new Set(existingServices)]
            const fullServiceInfo = []
            uniqueServices.forEach(service => {
                const serviceInfo = services.find(s => s.code === service)
                fullServiceInfo.push(serviceInfo)
            })
            setExistingClientServices(fullServiceInfo.sort((a, b) => a.code < b.code ? -1 : 1))
        }
    }

    const scrollLeft = (evt) => {
        const index = evt.target.id.replaceAll('serviceLeft', '')
        const serviceItemContainer = document.getElementById(`service${index}`)
        const scrollAmount = 200
        serviceItemContainer&& serviceItemContainer.scroll({
            top: 0,
            left: serviceItemContainer.scrollLeft - scrollAmount,
            behavior: 'smooth'
        });
    }

    const scrollRight = (evt) => {
        const index = evt.target.id.replaceAll('serviceRight', '')
        const serviceItemContainer = document.getElementById(`service${index}`)
        const scrollAmount = 200
        serviceItemContainer&& serviceItemContainer.scroll({
            top: 0,
            left: serviceItemContainer.scrollLeft + scrollAmount,
            behavior: 'smooth'
        });
    }
    
    useEffect(() => {
        updateCurrentPage('FormList')
        getAllServicesSorted()
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        updateIntakeForms(client._id)
        // eslint-disable-next-line
    },[client])

    useEffect(() => {
        if (existingIntakeForms) {
            getExistingClientServices()
        }
        // eslint-disable-next-line
    },[existingIntakeForms, services])

  return (
    <div className='FormList'>
        { loading ?
        <div className='loading'>
            <div className="spinner-border" role="status"></div>
        </div>
        :
        <>
        <div className='headerContent'>
            <img src={require('./ClientHead.png')} alt="" />
            <div className='mainHeaderContent'>
                <div className='companyName'>
                    {capitalizeFirstLetter(client.companyName)}
                </div>
                <div className='instructions'>
                    Below are all forms available for your company (sorted by service). Please click on a form to fill out the necessary information.
                </div>
            </div>
            
        </div>
        { existingClientServices &&
            <>
            { existingClientServices.map((service, serviceIdx) => (
                <div key={serviceIdx} className='serviceItem' id={`serviceItem${serviceIdx}`}>
                    <div className='serviceItemHead'>
                        <div className='serviceItemTitle'>{service.name}</div>
                        <div className='buttonContainer'>
                            <button className='scrollLeft' onMouseDown={scrollLeft} id={`serviceLeft${serviceIdx}`}>
                                <svg width="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.2218 22.7773L14.7773 17.3329L20.2218 11.8885" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                            <button className='scrollRight' onMouseDown={scrollRight} id={`serviceRight${serviceIdx}`}>
                                <svg width="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.7773 22.7773L20.2218 17.3329L14.7773 11.8885" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className='serviceItemContainer' id={`service${serviceIdx}`}>
                        { existingIntakeForms.map((form, formIdx) => (
                            ((form.service === service.code)) &&
                            <FormListItem key={formIdx} form={form} client={client} updateIntakeForms={updateIntakeForms} />
                        ))}
                    </div>
                </div>
            ))}
            </>
        }
        { (!existingIntakeForms || Object.keys(existingIntakeForms).length===0 || existingIntakeForms.length===0) &&
            <div className='emptyList'>
                <div className='text-center'>
                    <h4 className='errorText'>No Forms Available.</h4>
                    <h5>Please contact Riahi Patents to generate forms.</h5>
                </div>
            </div>
        }
        </>
        }
    </div>
  )
}
