import './IntakeFormNotes.css'

export default function IntakeFormNotes({notes}) {
  
  const handleDropdownClick = (evt) => {
    // prevent dropdown from closing when clicked
    evt.stopPropagation()
  }

  return (
    <div onClick={handleDropdownClick} className="IntakeFormNotes dropdown-menu notesDropDown" aria-labelledby={`notesDropDownMenu`}>
      <div className='noteHeader'>
        <svg width="40" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_296_4756)">
            <rect x="2" y="1.86328" width="48" height="48" rx="10" fill="white"/>
            <rect x="2.5" y="2.36328" width="47" height="47" rx="9.5" stroke="#1A3860" strokeOpacity="0.08"/>
          </g>
          <rect x="12" y="17" width="26" height="3.25" rx="1.625" fill="#192434"/>
          <rect x="15.25" y="23.5" width="19.5" height="3.25" rx="1.625" fill="#192434"/>
          <rect x="20.668" y="31.083" width="8.66667" height="3.25" rx="1.625" fill="#192434"/>
        </svg>
        <span>
          &nbsp;&nbsp;&nbsp;Notes
        </span>
      </div>
      <div className='noteContainer'>
        <div className='noteBody'>
          {notes}
        </div>
      </div>
      
    </div>
  )
}
