export const formNotificationStyle1 = (elementID, text, textColor, backColor, opacity) => {
    const notification = document.getElementById(elementID)
    textColor = textColor || 'white'
    backColor = backColor || 'black'
    opacity = opacity || 1
    if (notification) {
        notification.innerHTML=text
        notification.style.color=textColor
        notification.style.backgroundColor=backColor
        notification.style.borderRadius='10px'
        notification.style.fontWeight='bold'
        notification.style.opacity=opacity
        setTimeout(()=>{
            notification.innerHTML=''
        },3000)
    }
}

export const formNotificationStyle2 = (elementID, text, textColor, opacity) => {
    const notification = document.getElementById(elementID)
    textColor = textColor || 'white'
    opacity = opacity || 1
    if (notification) {
        notification.innerHTML=text
        notification.style.color=textColor
        notification.style.fontWeight='bold'
        notification.style.opacity=opacity
        setTimeout(()=>{
            notification.innerHTML=''
        },3000)
    }
}