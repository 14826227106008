import './IntakeForm.css'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getIntakeForm } from '../../utilities/api/clients'
import { getServices } from '../../utilities/api/services'
import IntakeForm1 from '../../components/IntakeForm1/IntakeForm1'
import IntakeForm2 from '../../components/IntakeForm2/IntakeForm2'
import IntakeForm3 from '../../components/IntakeForm3/IntakeForm3'
import IntakeForm4 from '../../components/IntakeForm4/IntakeForm4'
import IntakeForm5 from '../../components/IntakeForm5/IntakeForm5'


export default function IntakeForm({ client, updateCurrentPage }) {
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [form, setForm] = useState(null)
    const [title, setTitle] = useState('')
    const [services, setServices] = useState(null)

    const updateIntakeForm = async (clientID, formID) => {
        setLoading(true)
        try {
            const newForm = await getIntakeForm(clientID, formID)
            setForm(newForm)
            // console.log(newForm)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const getAllServicesSorted = async () => {
        try {
            const serviceList = await getServices()
            serviceList.sort((a, b) => a.name < b.name ? -1 : 1)
            setServices(serviceList)
        } catch (error) {
            console.log(error)
        }
        
    }

    useEffect(() => {
        getAllServicesSorted()
        updateCurrentPage('IntakeForm')
        updateIntakeForm(client._id, params.formID)
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        if (form && services) {
            const serviceInfo = services.find(s => s.code === form.service)
            setTitle(()=>serviceInfo.name)
        }
        // eslint-disable-next-line
    },[form, services])
    
  return (
    <div className='IntakeForm'>
        { loading &&
        <div className='loading'>
            <div className="spinner-border" role="status"></div>
        </div>
        }
        { (!loading && form) &&
        <div className='formContainer'>
            { (form.service === 'IPGAPS' || form.service === 'CPAF' || form.service === 'USPAF' || form.service === 'PCTAF' || form.service === 'USNPAF') &&
            <>
                <IntakeForm1 title={`${title} (${form.projectNumber})`} client={client} form={form} />
            </>
            }
            { (form.service === 'FTOA') &&
            <>
                <IntakeForm2 title={`${title} (${form.projectNumber})`} client={client} form={form} />
            </>
            }
            { (form.service === 'CTAF'|| form.service === 'USTAF' || form.service === 'CTS' || form.service === 'USTS' || form.service === 'TAF' || form.service === 'TS') &&
            <>
                <IntakeForm3 title={`${title} (${form.projectNumber})`} client={client} form={form} />
            </>
            }
            { form.service === 'IPSA' &&
            <>
                <IntakeForm4 title={`${title} (${form.projectNumber})`} client={client} form={form} />
            </>
            }
            { form.service === 'LA' &&
            <>
                <IntakeForm5 title={`${title} (${form.projectNumber})`} client={client} form={form} />
            </>
            }
        </div>
        }
    </div>
  )
}
