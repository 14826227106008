import { useEffect, useState } from 'react';
import './IntakeForm4.css'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter';
import { formNotificationStyle2 } from '../../utilities/services/formNotification';
import { Link, useNavigate } from 'react-router-dom';
import IntakeFormNotes from '../IntakeFormNotes/IntakeFormNotes';
import { updateIntakeForm } from '../../utilities/api/clients';
// import { createEmailNotifications } from '../../utilities/api/clients';
// import { create } from '../../utilities/api/intakeForm1s';

export default function IntakeForm4({ client, title, form}) {
    const firstUpdateDate = '2024-07-04';
    // console.log(form)
    const date = new Date();
    const [patentCount, setPatentCount] = useState(form.companyPatentItems.length);
    const [trademarkCount, setTrademarkCount] = useState(form.companyTrademarkItems.length);
    const [unfiledIPCount, setUnfiledIPCount] = useState(form.unfiledIPItems.length);
    const [publicationCount, setPublicationCount] = useState(form.publicationItems.length);
    const [domainNameItemsCount, setDomainNameItemsCount] = useState(form.domainNameItems.length);
    const navigate = useNavigate();
    const defaultFormData = {
        clientID: form.clientID,
        projectNumber: form.projectNumber,
        status: form.status,
        submissionDate: date.toISOString().split('T')[0],
        // approvelDate: client.approvelDate,
        clientNumber: form.clientNumber,
        service: form.service,
        companyName: form.companyName,
        companyAddress: form.companyAddress,
        companyRepresentative: capitalizeFirstLetter(convertToString(form.companyRepresentative)),
        industry: form.industry || '',
        companyPatents: form.companyPatents || '',
        companyPatentItems: form.companyPatentItems || [],
        companyTrademarks: form.companyTrademarks || '',
        companyTrademarkItems: form.companyTrademarkItems || [],
        unfiledIP: form.unfiledIP || '',
        unfiledIPItems: form.unfiledIPItems || [],
        companyTradeSecrets: form.companyTradeSecrets || '',
        companyTradeSecretsDescription1: form.companyTradeSecretsDescription1 || [],
        companyTradeSecretsDescription2: form.companyTradeSecretsDescription2 || [],
        concernsWithInfringement: form.concernsWithInfringement || '',
        concernsWithInfringementDescription1: form.concernsWithInfringementDescription1 || '',
        knownCompetitors: form.knownCompetitors || '',
        knownCompetitorsDescription1: form.knownCompetitorsDescription1 || '',
        customSoftware: form.customSoftware || '',
        customSoftwareDescription1: form.customSoftwareDescription1 || '',
        customSoftwareDescription2: form.customSoftwareDescription2 || '',
        customSoftwareDescription3: form.customSoftwareDescription3 || '',
        publications: form.publications || '',
        publicationItems: form.publicationItems || [],
        licenses: form.licenses || '',
        licensesDescription1: form.licensesDescription1 || '',
        licensesDescription2: form.licensesDescription2 || '',
        licensesDescription3: form.licensesDescription3 || '',
        companyBrands: form.companyBrands || '',
        companyBrandsDescription1: form.companyBrandsDescription1 || '',
        companyBrandsDescription2: form.companyBrandsDescription2 || '',
        productBrands: form.productBrands || '',
        productBrandsDescription1: form.productBrandsDescription1 || '',
        productBrandsDescription2: form.productBrandsDescription2 || '',
        marketingMaterials: form.marketingMaterials || '',
        marketingMaterialsDescription1: form.marketingMaterialsDescription1 || '',
        marketingMaterialsDescription2: form.marketingMaterialsDescription2 || '',
        marketingMaterialsDescription3: form.marketingMaterialsDescription3 || '',
        trainingMaterials: form.trainingMaterials || '',
        trainingMaterialsDescription1: form.trainingMaterialsDescription1 || '',
        trainingMaterialsDescription2: form.trainingMaterialsDescription2 || '',
        trainingMaterialsDescription3: form.trainingMaterialsDescription3 || '',
        domainNameItems: form.domainNameItems || [],
        productDesigns: form.productDesigns || '',
        productDesignsDescription1: form.productDesignsDescription1 || '',
        contracts: form.contracts || '',
        contractsDescription1: form.contractsDescription1 || '',
        contractsDescription2: form.contractsDescription2 || '',
        contractsDescription3: form.contractsDescription3 || '',
        departmentalStandardOperatingProcedures: form.departmentalStandardOperatingProcedures || '',
        departmentalStandardOperatingProceduresDescription1: form.departmentalStandardOperatingProceduresDescription1 || '',
        departmentalStandardOperatingProceduresDescription2: form.departmentalStandardOperatingProceduresDescription2 || '',
        departmentalStandardOperatingProceduresDescription3: form.departmentalStandardOperatingProceduresDescription3 || '',
        certifications: form.certifications || '',
        certificationsDescription1: form.certificationsDescription1 || '',
        fileUploads: form.fileUploads || [],
    }
    const [renderCompanyPatentItems, setRenderCompanyPatentItems] = useState(false);
    const [renderCompanyTrademarkItems, setRenderCompanyTrademarkItems] = useState(false);
    const [renderCompanyTradeSecretItems, setRenderCompanyTradeSecretItems] = useState(false);
    const [renderUnfiledIPItems, setRenderUnfiledIPItems] = useState(false);
    const [renderConcernsWithInfringementItems, setRenderConcernsWithInfringementItems] = useState(false);
    const [renderKnownCompetitorsItems, setRenderKnownCompetitorsItems] = useState(false);
    const [renderCustomSoftwareItems, setRenderCustomSoftwareItems] = useState(false);
    const [renderPublicationItems, setRenderPublicationItems] = useState(false);
    const [renderLicenseItems, setRenderLicenseItems] = useState(false);
    const [renderCompanyBrandItems, setRenderCompanyBrandItems] = useState(false);
    const [renderProductBrandItems, setRenderProductBrandItems] = useState(false);
    const [renderMarketingMaterialItems, setRenderMarketingMaterialItems] = useState(false);
    const [renderTrainingMaterialItems, setRenderTrainingMaterialItems] = useState(false);
    const [renderProductDesignItems, setRenderProductDesignItems] = useState(false);
    const [renderContractItems, setRenderContractItems] = useState(false);
    const [renderDepartmentalStandardOperatingProcedureItems, setRenderDepartmentalStandardOperatingProcedureItems] = useState(false);
    const [renderCertificationItems, setRenderCertificationItems] = useState(false);
    const [generalInformationComplete, setGeneralInformationComplete] = useState(false);
    const [technicalSection1Complete, setTechnicalSection1Complete] = useState(false);
    const [technicalSection2Complete, setTechnicalSection2Complete] = useState(true);// set to true since section is optional
    const [fileUploadData, setFileUploadData] = useState([]);
    const tabCompletion = {
        0: generalInformationComplete,
        1: technicalSection1Complete,
        2: technicalSection2Complete,
    };
    const [disableAllFields] = useState(['submitted', 'complete', 'changesSubmitted', 'changesApproved'].includes(form.status) ? true : false);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [formData] = useState(new FormData());
    const tabs = [
        'generalInformation', 
        'technicalSection1', 
        'technicalSection2', 
    ];
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    function convertToString(array) {
        // convert array to string separated by commas and spaces
        let string = ''
        array.forEach((item, idx) => {
            if (idx === array.length - 1) {
                string += item
            } else {
                string += item + ', '
            }
        })
        return string
    }

    const updateFormData = (defaultFormData) => {
        for (var pair of Object.entries(defaultFormData)) {
            formData.append(pair[0], pair[1]);
        }
        formData.delete('companyPatentItems');
        formData.delete('companyTrademarkItems');
        formData.delete('unfiledIPItems');
        formData.delete('publicationItems');
        formData.delete('domainNameItems');
        if (patentCount > 0) {
            form.companyPatentItems.forEach((item, idx) => {
                formData.append(`companyPatentItem${idx+1}`, item.description);
            });
        }
        if (trademarkCount > 0) {
            form.companyTrademarkItems.forEach((item, idx) => {
                formData.append(`companyTrademarkItem${idx+1}`, item.description);
            });
        }
        if (unfiledIPCount > 0) {
            form.unfiledIPItems.forEach((item, idx) => {
                formData.append(`unfiledIPItem${idx+1}Type`, item.ipType);
                formData.append(`unfiledIPItem${idx+1}Ownership`, item.ownership);
                formData.append(`unfiledIPItem${idx+1}DateOfFirstUse`, item.dateOfFirstUse);
            });
        }
        if (publicationCount > 0) {
            form.publicationItems.forEach((item, idx) => {
                formData.append(`publicationItem${idx+1}Title`, item.title);
                formData.append(`publicationItem${idx+1}Authors`, item.authors);
                formData.append(`publicationItem${idx+1}Date`, item.date);
                formData.append(`publicationItem${idx+1}AdditionalInfo`, item.additionalInfo);
            });
        }
        if (domainNameItemsCount > 0) {
            form.domainNameItems.forEach((item, idx) => {
                formData.append(`domainNameItem${idx+1}Name`, item.domainName);
                formData.append(`domainNameItem${idx+1}FirstUse`, item.dateOfFirstUse);
                formData.append(`domainNameItem${idx+1}ExpiryDate`, item.expiryDate);
            });
        }
        // // log form data
        // for (var formPair of formData.entries()) {
        //     console.log(formPair[0]+ ', ' + formPair[1]);
        // }
    }

    const handleNextClick = async (evt) => {
        const activeTab = document.getElementById(tabs[activeTabIndex]);
        const tabCompletionStatus = tabCompletion;
        if (!tabCompletionStatus[activeTabIndex]) {
            formNotificationStyle2('tabSwitchNotification', 'Please complete all required fields before proceeding!', '#CA150C', 0.8 )
        } else if (activeTabIndex < tabs.length-1) {
            activeTab.scrollIntoView({behavior: 'smooth', block: 'start'});
            setActiveTabIndex(activeTabIndex + 1);
        }
    }

    const handleBackClick = (evt) => {
        if (activeTabIndex > 0) {
            setActiveTabIndex(activeTabIndex - 1);
        }
    }

    const handleTabClick = (index) => {
        const tabCompletionStatus = tabCompletion;
        const activeTab = document.getElementById(tabs[activeTabIndex]);
        if (index > activeTabIndex && !tabCompletionStatus[activeTabIndex]) {
            formNotificationStyle2('tabSwitchNotification', 'Please complete all required fields before proceeding!', '#CA150C', 0.8 )
        } else {
            activeTab.scrollIntoView({behavior: 'smooth', block: 'start'});
            setActiveTabIndex(index);
        }
    }

    const handleAddPatentItemClick = (evt) => {
        setPatentCount(patentCount+1);
    }

    const handleAddTrademarkItemClick = (evt) => {
        setTrademarkCount(trademarkCount+1);
    }

    const handleAddUnfiledIPItemClick = (evt) => {
        setUnfiledIPCount(unfiledIPCount+1);
    }

    const handleAddPublicationItemClick = (evt) => {
        setPublicationCount(publicationCount+1);
    }

    const handleAddDomainNameItemClick = (evt) => {
        setDomainNameItemsCount(domainNameItemsCount+1);
    }

    const checkValidTotalFileSize = (files) => {
        let totalFileSize = 0;
        for (let i = 0; i < files.length; i++) {
            totalFileSize += files[i].size;
        }
        return totalFileSize < 20000000;
    }

    const checkValidFileTypes = (files) => {
        const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint', 'text/plain', 'application/zip', 'application/x-zip-compressed', 'application/x-rar-compressed', 'application/octet-stream', 'application/vnd.rar', 'application/x-7z-compressed', 'application/x-zip', 'application/x-zip-compressed', 'multipart/x-zip', 'application/x-compressed', 'application/x-rar', 'application/x-tar', 'application/gzip', 'application/x-gzip', 'application/x-bzip2', 'application/x-bzip', 'application/x-7z-compressed', 'application/x-7z-compressed', 'application/x-7z-compressed', 'application/x-7z-compressed'];
        for (let i = 0; i < files.length; i++) {
            if (!validFileTypes.includes(files[i].type)) {
                return false;
            }
        }
        return true;
    }

    const updateFileUploadData = (files) => {
        // const fileUploadData = [];
        for (let i = 0; i < files.length; i++) {
            fileUploadData.push(files[i]);
        }
        setFileUploadData([...fileUploadData]);
    }

    const handleRemoveFile = (evt) => {
        // console.log(evt.target.id)
        fileUploadData.splice(evt.target.id, 1);
        setFileUploadData([...fileUploadData]);
        formData.delete('fileUploads');
        for (let i = 0; i < fileUploadData.length; i++) {
            formData.append('fileUploads', fileUploadData[i]);
        }        
        if (fileUploadData.length === 0) {
            const fileUploadNotification = document.getElementById('fileUploadNotification');
            fileUploadNotification.innerHTML = 'No files selected!';
            fileUploadNotification.style.opacity = 0.8;
            fileUploadNotification.style.color = 'black';
        }
    }

    // console.log(Object.fromEntries(formData.entries()));

    const handleFormChange = (evt) => {
        if (evt.target.name === 'fileUploads') {
            const fileUploadNotification = document.getElementById('fileUploadNotification');
            if (evt.target.files.length!==0 && checkValidTotalFileSize(evt.target.files) && checkValidFileTypes(evt.target.files)) {
                fileUploadNotification.style.opacity = 0.8;
                fileUploadNotification.style.color = '#0A7637';
                fileUploadNotification.style.fontWeight = 'bold';
                fileUploadNotification.innerHTML = 'File(s) successfully added!';
                for (let i = 0; i < evt.target.files.length; i++) {
                    formData.append(evt.target.name, evt.target.files[i]); // Append each file individually
                }
                updateFileUploadData(evt.target.files);
            } else if (evt.target.files.length!==0 && !checkValidTotalFileSize(evt.target.files)) {
                fileUploadNotification.style.opacity = 0.8;
                fileUploadNotification.style.color = '#CA150C';
                fileUploadNotification.style.fontWeight = 'bold';
                fileUploadNotification.innerHTML = 'Error: File(s) exceed 20MB!';
                updateFileUploadData([]);
            } else if (evt.target.files.length!==0 && !checkValidFileTypes(evt.target.files)) {
                fileUploadNotification.style.opacity = 0.8;
                fileUploadNotification.style.color = '#CA150C';
                fileUploadNotification.style.fontWeight = 'bold';
                fileUploadNotification.innerHTML = 'Error: Invalid file type(s) added!';
                updateFileUploadData([]);
            } else if (evt.target.files.length === 0) {
                fileUploadNotification.innerHTML = 'No files selected!';
                fileUploadNotification.style.opacity = 0.8;
                fileUploadNotification.style.color = 'black';
                updateFileUploadData([]);
            }
            
        } else if (evt.target.name === 'companyPatents') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('companyPatentItems', []);
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderCompanyPatentItems(false);
            } else {
                setRenderCompanyPatentItems(true);
            }
        } else if (evt.target.name === 'companyTrademarks') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('companyTrademarkItems', []);
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderCompanyTrademarkItems(false);
            } else {
                setRenderCompanyTrademarkItems(true);
            }
        } else if (evt.target.name === 'companyTradeSecrets') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('companyTradeSecretsDescription1', '');
            formData.set('companyTradeSecretsDescription2', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderCompanyTradeSecretItems(false);
            } else {
                setRenderCompanyTradeSecretItems(true);
            }
        } else if (evt.target.name === 'unfiledIP') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('unfiledIPItems', []);
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderUnfiledIPItems(false);
            } else {
                setRenderUnfiledIPItems(true);
            }
        } else if (evt.target.name === 'concernsWithInfringement') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('concernsWithInfringementDescription1', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderConcernsWithInfringementItems(false);
            } else {
                setRenderConcernsWithInfringementItems(true);
            }
        } else if (evt.target.name === 'knownCompetitors') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('knownCompetitorsDescription1', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderKnownCompetitorsItems(false);
            } else {
                setRenderKnownCompetitorsItems(true);
            }
        } else if (evt.target.name === 'customSoftware') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('customSoftwareDescription1', '');
            formData.set('customSoftwareDescription2', '');
            formData.set('customSoftwareDescription3', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderCustomSoftwareItems(false);
            } else {
                setRenderCustomSoftwareItems(true);
            }
        } else if (evt.target.name === 'publications') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('publicationItems', []);
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderPublicationItems(false);
            } else {
                setRenderPublicationItems(true);
            }
        } else if (evt.target.name === 'licenses') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('licensesDescription1', '');
            formData.set('licensesDescription2', '');
            formData.set('licensesDescription3', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderLicenseItems(false);
            } else {
                setRenderLicenseItems(true);
            }
        } else if (evt.target.name === 'companyBrands') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('companyBrandsDescription1', '');
            formData.set('companyBrandsDescription2', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderCompanyBrandItems(false);
            } else {
                setRenderCompanyBrandItems(true);
            }
        } else if (evt.target.name === 'productBrands') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('productBrandsDescription1', '');
            formData.set('productBrandsDescription2', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderProductBrandItems(false);
            } else {
                setRenderProductBrandItems(true);
            }
        } else if (evt.target.name === 'marketingMaterials') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('marketingMaterialsDescription1', '');
            formData.set('marketingMaterialsDescription2', '');
            formData.set('marketingMaterialsDescription3', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderMarketingMaterialItems(false);
            } else {
                setRenderMarketingMaterialItems(true);
            }
        } else if (evt.target.name === 'trainingMaterials') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('trainingMaterialsDescription1', '');
            formData.set('trainingMaterialsDescription2', '');
            formData.set('trainingMaterialsDescription3', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderTrainingMaterialItems(false);
            } else {
                setRenderTrainingMaterialItems(true);
            }
        } else if (evt.target.name === 'domainNameItems') {
            formData.set(evt.target.name, evt.target.value);
        } else if (evt.target.name === 'productDesigns') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('productDesignsDescription1', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderProductDesignItems(false);
            } else {
                setRenderProductDesignItems(true);
            }
        } else if (evt.target.name === 'contracts') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('contractsDescription1', '');
            formData.set('contractsDescription2', '');
            formData.set('contractsDescription3', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderContractItems(false);
            } else {
                setRenderContractItems(true);
            }
        } else if (evt.target.name === 'departmentalStandardOperatingProcedures') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('departmentalStandardOperatingProceduresDescription1', '');
            formData.set('departmentalStandardOperatingProceduresDescription2', '');
            formData.set('departmentalStandardOperatingProceduresDescription3', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderDepartmentalStandardOperatingProcedureItems(false);
            } else {
                setRenderDepartmentalStandardOperatingProcedureItems(true);
            }
        } else if (evt.target.name === 'certifications') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('certificationsDescription1', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderCertificationItems(false);
            } else {
                setRenderCertificationItems(true);
            }
        } else {
            formData.set(evt.target.name, evt.target.value);
        }
        checkFormCompletion(formData);
        // console.log(formData)
    }

    const approveChanges = async (evt) => {
        setIsSubmitting(true);
        try {
            await updateIntakeForm(client._id, form._id, {
                service: form.service,
                status: 'changesApproved'
            })
            formNotificationStyle2('tabSwitchNotification', 'Changes successfully approved!', '#0A7637', 0.8)
            setTimeout(() => {
                navigate('/');
            }, 1500);
        } catch (error) {
            console.log(error)
            formNotificationStyle2('tabSwitchNotification', 'Changes could not be approved!', '#CA150C', 0.8)
        }
        setIsSubmitting(false);
    }

    const checkFormCompletion = (formData) => {
        const requiredFieldsByTab = {
            0: [
                'companyName', 
                'companyAddress', 
                'companyRepresentative',
                (form.createdAt.split('T')[0]>=firstUpdateDate) && 'industry',
            ],
            1: [
                'companyPatents',
                'comanyTrademarks',
                'companyTradeSecrets',
                renderCompanyTradeSecretItems && 'companyTradeSecretsDescription1',
                renderCompanyTradeSecretItems && 'companyTradeSecretsDescription2',
                (form.createdAt.split('T')[0]>=firstUpdateDate) && 'unfiledIP',
                (form.createdAt.split('T')[0]>=firstUpdateDate) && 'concernsWithInfringement',
                (form.createdAt.split('T')[0]>=firstUpdateDate && renderConcernsWithInfringementItems) && 'concernsWithInfringementDescription1',
                (form.createdAt.split('T')[0]>=firstUpdateDate) && 'knownCompetitors',
                (form.createdAt.split('T')[0]>=firstUpdateDate && renderKnownCompetitorsItems) && 'knownCompetitorsDescription1',
                'customSoftware',
                renderCustomSoftwareItems && 'customSoftwareDescription1',
                renderCustomSoftwareItems && 'customSoftwareDescription2',
                renderCustomSoftwareItems && 'customSoftwareDescription3',
                'publications',
                'licenses',
                renderLicenseItems && 'licensesDescription1',
                renderLicenseItems && 'licensesDescription2',
                renderLicenseItems && 'licensesDescription3',
                'companyBrands',
                renderCompanyBrandItems && 'companyBrandsDescription1',
                renderCompanyBrandItems && 'companyBrandsDescription2',
                'productBrands',
                renderProductBrandItems && 'productBrandsDescription1',
                renderProductBrandItems && 'productBrandsDescription2',
                'marketingMaterials',
                renderMarketingMaterialItems && 'marketingMaterialsDescription1',
                renderMarketingMaterialItems && 'marketingMaterialsDescription2',
                renderMarketingMaterialItems && 'marketingMaterialsDescription3',
                'trainingMaterials',
                renderTrainingMaterialItems && 'trainingMaterialsDescription1',
                renderTrainingMaterialItems && 'trainingMaterialsDescription2',
                renderTrainingMaterialItems && 'trainingMaterialsDescription3',
                'productDesigns',
                renderProductDesignItems && 'productDesignsDescription1',
                'contracts',
                renderContractItems && 'contractsDescription1',
                renderContractItems && 'contractsDescription2',
                renderContractItems && 'contractsDescription3',
                'departmentalStandardOperatingProcedures',
                renderDepartmentalStandardOperatingProcedureItems && 'departmentalStandardOperatingProceduresDescription1',
                renderDepartmentalStandardOperatingProcedureItems && 'departmentalStandardOperatingProceduresDescription2',
                renderDepartmentalStandardOperatingProcedureItems && 'departmentalStandardOperatingProceduresDescription3',
                'certifications',
                renderCertificationItems && 'certificationsDescription1',
            ],
            2: [],
        };
        const checkFormCompletion = (fields) => {
            let isComplete = true;
            for (var pair of formData.entries()) {
                if (fields.includes(pair[0]) && pair[1] === '') {
                    isComplete = false;
                }
            }
            return isComplete;
        };
        if (activeTabIndex in requiredFieldsByTab) {
            const isComplete = checkFormCompletion(requiredFieldsByTab[activeTabIndex]);
            if (activeTabIndex === 0) setGeneralInformationComplete(isComplete);
            if (activeTabIndex === 1) setTechnicalSection1Complete(isComplete);
            if (activeTabIndex === 2) setTechnicalSection2Complete(isComplete);
        }
    }

    const handleSave = async (evt) => {
        evt.preventDefault();
        setIsSaving(true);
        // console.log(Object.fromEntries(formData.entries()));
        try {
            const response = await fetch(`/api/clients/${client._id}/intakeForms/${form._id}/update`, {
                method: 'PUT',
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: formData,
            });
            const result = await response.json();
            if (result && result.status === 'success') {
                setIsSaving(false);
                formNotificationStyle2('tabSwitchNotification', 'Saved!', '#0A7637', 0.8)
            } else {
                setIsSaving(false);
                formNotificationStyle2('tabSwitchNotification', 'Form could not be saved!', '#CA150C', 0.8)
                console.log(result);
            }
        } catch (error) {
            setIsSaving(false);
            formNotificationStyle2('tabSwitchNotification', 'Form could not be saved!', '#CA150C', 0.8)
            console.log(error);
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        setIsSubmitting(true);
        formData.delete('status');
        formData.append('status', 'submitted');
        // // log form data
        // for (var pair of formData.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]);
        // }

        try {
            const response = await fetch(`/api/clients/${client._id}/intakeForms/${form._id}/update`, {
                method: 'PUT',
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: formData,
            });
            const result = await response.json();
            if (result === 'Unauthorized') {
                setIsSubmitting(false);
                formNotificationStyle2('tabSwitchNotification', 'Form could not be submitted!', '#CA150C', 0.8)
                console.log(result);
            } else {
                setIsSubmitting(false);
                // await createEmailNotifications(client._id, form._id, form.service);
                formNotificationStyle2('tabSwitchNotification', 'Form successfully submitted!', '#0A7637', 0.8)
                setTimeout(() => {
                    navigate('/');
                }, 1500);
            }
        } catch (error) {
            setIsSubmitting(false);
            formNotificationStyle2('tabSwitchNotification', 'Form could not be submitted!', '#CA150C', 0.8)
            console.log(error);
        }
    }

    useEffect(() => {
        setIsFormComplete(generalInformationComplete && technicalSection1Complete && technicalSection2Complete);
    },[generalInformationComplete, technicalSection1Complete, technicalSection2Complete])


    useEffect(() => {
        updateFormData(defaultFormData);
        if (defaultFormData.companyPatents === 'N' || defaultFormData.companyPatents === '') {
            setRenderCompanyPatentItems(false);
        } else {
            setRenderCompanyPatentItems(true);
        }
        if (defaultFormData.companyTrademarks === 'N' || defaultFormData.companyTrademarks === '') {
            setRenderCompanyTrademarkItems(false);
        } else {
            setRenderCompanyTrademarkItems(true);
        }
        if (defaultFormData.companyTradeSecrets === 'N' || defaultFormData.companyTradeSecrets === '') {
            setRenderCompanyTradeSecretItems(false);
        } else {
            setRenderCompanyTradeSecretItems(true);
        }
        if (defaultFormData.unfiledIP === 'N' || defaultFormData.unfiledIP === '') {
            setRenderUnfiledIPItems(false);
        } else {
            setRenderUnfiledIPItems(true);
        }
        if (defaultFormData.concernsWithInfringement === 'N' || defaultFormData.concernsWithInfringement === '') {
            setRenderConcernsWithInfringementItems(false);
        } else {
            setRenderConcernsWithInfringementItems(true);
        }
        if (defaultFormData.knownCompetitors === 'N' || defaultFormData.knownCompetitors === '') {
            setRenderKnownCompetitorsItems(false);
        } else {
            setRenderKnownCompetitorsItems(true);
        }
        if (defaultFormData.customSoftware === 'N' || defaultFormData.customSoftware === '') {
            setRenderCustomSoftwareItems(false);
        } else {
            setRenderCustomSoftwareItems(true);
        }
        if (defaultFormData.publications === 'N' || defaultFormData.publications === '') {
            setRenderPublicationItems(false);
        } else {
            setRenderPublicationItems(true);
        }
        if (defaultFormData.licenses === 'N' || defaultFormData.licenses === '') {
            setRenderLicenseItems(false);
        } else {
            setRenderLicenseItems(true);
        }
        if (defaultFormData.companyBrands === 'N' || defaultFormData.companyBrands === '') {
            setRenderCompanyBrandItems(false);
        } else {
            setRenderCompanyBrandItems(true);
        }
        if (defaultFormData.productBrands === 'N' || defaultFormData.productBrands === '') {
            setRenderProductBrandItems(false);
        } else {
            setRenderProductBrandItems(true);
        }
        if (defaultFormData.marketingMaterials === 'N' || defaultFormData.marketingMaterials === '') {
            setRenderMarketingMaterialItems(false);
        } else {
            setRenderMarketingMaterialItems(true);
        }
        if (defaultFormData.trainingMaterials === 'N' || defaultFormData.trainingMaterials === '') {
            setRenderTrainingMaterialItems(false);
        } else {
            setRenderTrainingMaterialItems(true);
        }
        if (defaultFormData.productDesigns === 'N' || defaultFormData.productDesigns === '') {
            setRenderProductDesignItems(false);
        } else {
            setRenderProductDesignItems(true);
        }
        if (defaultFormData.contracts === 'N' || defaultFormData.contracts === '') {
            setRenderContractItems(false);
        } else {
            setRenderContractItems(true);
        }
        if (defaultFormData.departmentalStandardOperatingProcedures === 'N' || defaultFormData.departmentalStandardOperatingProcedures === '') {
            setRenderDepartmentalStandardOperatingProcedureItems(false);
        } else {
            setRenderDepartmentalStandardOperatingProcedureItems(true);
        }
        if (defaultFormData.certifications === 'N' || defaultFormData.certifications === '') {
            setRenderCertificationItems(false);
        } else {
            setRenderCertificationItems(true);
        }
        // formData.append('fileUploads', [])
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        checkFormCompletion(formData);
        //eslint-disable-next-line
    }, [formData, defaultFormData])
  return (
        <div className='IntakeForm4 IntakeFormGlobal'>
            <div className='upperSection'>
                <div className='upperButtons'>
                    <Link to={'/'} className='backLink'>
                        <button className='backButton'>
                            <span>
                                <svg width="7" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 15L1 8L8 1" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                            <span>&nbsp;&nbsp;Back</span>
                        </button>
                    </Link>
                    { ['new', 'inProgress'].includes(form.status) &&
                    <button type='button' id='saveButton' onClick={handleSave} className='saveButton' disabled={isSaving}>Save</button>
                    }
                </div>
                <div className='firstSection'>
                    <div className='sec1p1'>
                        <div className='serviceTitle'>
                            {title}
                        </div>
                        <div className={`formStatus ${form.status}`}>
                            { ['inProgress', 'submitted', 'changesApproved'].includes(form.status) &&
                            <svg width="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 0.96875C4.78854 0.96875 0.96875 4.78854 0.96875 9.5C0.96875 14.2115 4.78854 18.0312 9.5 18.0312C14.2115 18.0312 18.0312 14.2115 18.0312 9.5C18.0312 4.78854 14.2115 0.96875 9.5 0.96875ZM13.4375 10.8125H9.5C9.32595 10.8125 9.15903 10.7434 9.03596 10.6203C8.91289 10.4972 8.84375 10.3303 8.84375 10.1562V4.25C8.84375 4.07595 8.91289 3.90903 9.03596 3.78596C9.15903 3.66289 9.32595 3.59375 9.5 3.59375C9.67405 3.59375 9.84097 3.66289 9.96404 3.78596C10.0871 3.90903 10.1562 4.07595 10.1562 4.25V9.5H13.4375C13.6115 9.5 13.7785 9.56914 13.9015 9.69221C14.0246 9.81528 14.0938 9.9822 14.0938 10.1562C14.0938 10.3303 14.0246 10.4972 13.9015 10.6203C13.7785 10.7434 13.6115 10.8125 13.4375 10.8125Z" fill="#DFB200"/>
                            </svg>                
                            }
                            { ['changesSubmitted'].includes(form.status) &&
                            <svg width="17" fill="#E21D12" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.311 45.311">
                                <path d="M22.675,0.02c-0.006,0-0.014,0.001-0.02,0.001c-0.007,0-0.013-0.001-0.02-0.001C10.135,0.02,0,10.154,0,22.656c0,12.5,10.135,22.635,22.635,22.635c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c12.5,0,22.635-10.135,22.635-22.635C45.311,10.154,35.176,0.02,22.675,0.02z M22.675,38.811c-0.006,0-0.014-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001c-2.046,0-3.705-1.658-3.705-3.705c0-2.045,1.659-3.703,3.705-3.703c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c2.045,0,3.706,1.658,3.706,3.703C26.381,37.152,24.723,38.811,22.675,38.811z M27.988,10.578c-0.242,3.697-1.932,14.692-1.932,14.692c0,1.854-1.519,3.356-3.373,3.356c-0.01,0-0.02,0-0.029,0c-0.009,0-0.02,0-0.029,0c-1.853,0-3.372-1.504-3.372-3.356c0,0-1.689-10.995-1.931-14.692C17.202,8.727,18.62,5.29,22.626,5.29c0.01,0,0.02,0.001,0.029,0.001c0.009,0,0.019-0.001,0.029-0.001C26.689,5.29,28.109,8.727,27.988,10.578z"/>
                            </svg>
                            }
                            { ['complete'].includes(form.status) &&
                            <svg width="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 1.96875C5.79592 1.96875 1.96875 5.79592 1.96875 10.5C1.96875 15.2041 5.79592 19.0312 10.5 19.0312C15.2041 19.0312 19.0312 15.2041 19.0312 10.5C19.0312 5.79592 15.2041 1.96875 10.5 1.96875ZM14.9399 7.6408L9.42744 14.2033C9.36697 14.2753 9.29173 14.3335 9.20681 14.3739C9.12189 14.4143 9.02928 14.436 8.93525 14.4375H8.92418C8.8322 14.4375 8.74125 14.4181 8.65723 14.3806C8.57322 14.3432 8.49802 14.2885 8.4365 14.2201L6.074 11.5951C6.014 11.5315 5.96733 11.4565 5.93673 11.3746C5.90612 11.2926 5.89221 11.2054 5.89579 11.118C5.89938 11.0306 5.9204 10.9448 5.95762 10.8657C5.99483 10.7865 6.04749 10.7156 6.11251 10.6571C6.17752 10.5986 6.25357 10.5537 6.33619 10.525C6.41881 10.4963 6.50634 10.4844 6.59362 10.49C6.6809 10.4956 6.76618 10.5187 6.84444 10.5577C6.9227 10.5967 6.99237 10.651 7.04936 10.7174L8.90695 12.7813L13.9351 6.7967C14.0478 6.6663 14.2074 6.58553 14.3793 6.57183C14.5511 6.55814 14.7215 6.61262 14.8535 6.72351C14.9855 6.8344 15.0686 6.99279 15.0848 7.16444C15.1009 7.33608 15.0489 7.5072 14.9399 7.6408Z" fill="#158444"/>
                            </svg>                    
                            }
                            <span className='statusText'>
                            {form.status === 'new' ? 'New' : form.status === 'inProgress' ? 'In Progress' : form.status === 'submitted' ? 'Submitted' : form.status === 'complete' ? 'Complete' : form.status === 'changesSubmitted' ? 'Changes Made' : form.status === 'changesApproved' ? 'Changes Approved' : ''}
                            </span>
                        </div>
                    </div>
                    { form.notes &&
                    <div className='notesContainer'>
                        <button className='notesButton dropdown' id={`formNotes`} data-title={`Add & View Form Notes`} data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5F6B7A" className="bi bi-sticky-fill" viewBox="0 0 16 16">
                                <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177z"/>
                            </svg>
                            <span>&nbsp;&nbsp;Notes</span>
                        </button>
                        <IntakeFormNotes formID={form._id} notes={form.notes} />
                    </div>
                    }
                </div>
                <div className="nav nav-pills flex-row navigation" id="v-pills-tab" role='tablist' aria-orientation="horizontal">
                {tabs.map((tab, index) => (
                    <button key={tab} onClick={() => handleTabClick(index)} className={`${tab} nav-link ${activeTabIndex === index ? 'active' : ''}`} id={`${tab}Tab`} type="button">
                        {tab === 'generalInformation' ? 'General Information' : tab === 'technicalSection1' ? 'IP Audit 1' : 'IP Audit 2'}
                    </button>
                ))}
                </div>
                <div className='content tab-content' id="v-pills-tabContent">
                    <form className='' id='intakeForm4' formEncType='multipart/form-data' onSubmit={handleSubmit}>
                    {tabs.map((tab, index) => (
                        <div key={tab} className={`tab-pane fade ${activeTabIndex === index ? 'show active' : ''}`} id={tab} role="tabpanel" aria-labelledby={`${tab}Tab`} >
                            {tab === 'generalInformation' &&
                            <div className='generalInformation'>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>General Information</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div className='formGroup'>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='companyName'>Legal name of client (company/person) <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='companyName' name='companyName' defaultValue={defaultFormData.companyName} onChange={handleFormChange} required disabled />
                                            </div>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='companyAddress'>Legal address of client <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='companyAddress' name='companyAddress' defaultValue={defaultFormData.companyAddress} onChange={handleFormChange} required disabled={disableAllFields} />
                                            </div>
                                        </div>
                                        <div className='formGroup'>
                                            <div className='formGroupItem'>
                                                { (form.createdAt.split('T')[0])>=firstUpdateDate &&
                                                <>
                                                <label className='form-label' htmlFor='industry'>Associated Technology / Industry <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='industry' name='industry' defaultValue={defaultFormData.industry} onChange={handleFormChange} required disabled={disableAllFields} />
                                                </>
                                                }
                                            </div>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='companyRepresentative'>Full name of representative(s) <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='companyRepresentative' name='companyRepresentative' defaultValue={defaultFormData.companyRepresentative} onChange={handleFormChange} required disabled={disableAllFields} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {tab === 'technicalSection1' &&
                            <div className='technicalSection1'>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>Technical Section</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <ol className='smallFont'>
                                            <li>
                                                This Intake Form intends to collect the information needed for intellectual property assets owned by your company. <span className='blueColorFont'>Please let our team know if you have any questions</span>.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Patents</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Does the company or you have any patents (filed/granted)? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('companyPatents') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="companyPatentsYes">
                                                    <input type="radio" name="companyPatents" id="companyPatentsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.companyPatents === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('companyPatents') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="companyPatentsNo">
                                                    <input type="radio" name="companyPatents" id="companyPatentsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.companyPatents === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sectionContent'>
                                    { renderCompanyPatentItems &&
                                    <>
                                        {patentCount>0 &&
                                        [...Array(patentCount)].map((element, index) => (
                                            <div key={index} className='mergedLabel'>
                                                <label className='form-label' htmlFor={`companyPatentItem${index+1}`}><span className='itemNum'>{index+1}</span>Patent</label>
                                                <textarea 
                                                    className='form-control' 
                                                    id={`companyPatentItem${index+1}`} 
                                                    name={`companyPatentItem${index+1}`} 
                                                    defaultValue={formData.get(`companyPatentItem${[index+1]}`) || ''} 
                                                    onChange={handleFormChange} 
                                                    placeholder={`Enter Patent ${index+1}`}
                                                    disabled={disableAllFields} 
                                                />
                                            </div>
                                        ))
                                        }
                                        <button className='incrementItem' type='button' onClick={handleAddPatentItemClick}>
                                            <svg width="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="27.5513" height="27.5513" rx="13.7756" fill="#1A5E9B"/>
                                                <path d="M13.5549 8.60986V18.5001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M8.60974 13.5549H18.4999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span>Add Patent</span>
                                        </button>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Trademarks</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Does the company or you have any trademarks (filed/granted)? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('companyTrademarks') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="companyTrademarksYes">
                                                    <input type="radio" name="companyTrademarks" id="companyTrademarksYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.companyTrademarks === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('companyTrademarks') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="companyTrademarksNo">
                                                    <input type="radio" name="companyTrademarks" id="companyTrademarksNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.companyTrademarks === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='companyTrademarks' name='companyTrademarks' onChange={handleFormChange} defaultValue={defaultFormData.companyTrademarks} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderCompanyTrademarkItems &&
                                    <>
                                        {trademarkCount>0 &&
                                        [...Array(trademarkCount)].map((element, index) => (
                                            <div key={index} className='mergedLabel'>
                                                <label className='form-label' htmlFor={`companyTrademarkItem${index+1}`}><span className='itemNum'>{index+1}</span>Trademark</label>
                                                <textarea 
                                                    className='form-control' 
                                                    id={`companyTrademarkItem${index+1}`}
                                                    name={`companyTrademarkItem${index+1}`} 
                                                    defaultValue={formData.get(`companyTrademarkItem${[index+1]}`) || ''}
                                                    placeholder={`Enter Trademark ${index+1}`}
                                                    onChange={handleFormChange} 
                                                    disabled={disableAllFields} 
                                                />
                                            </div>
                                        ))
                                        }
                                        <button className='incrementItem' type='button' onClick={handleAddTrademarkItemClick}>
                                            <svg width="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="27.5513" height="27.5513" rx="13.7756" fill="#1A5E9B"/>
                                                <path d="M13.5549 8.60986V18.5001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M8.60974 13.5549H18.4999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span>Add Trademark</span>
                                        </button>
                                    </>
                                    }
                                    </div>
                                </div>
                                { (form.createdAt.split('T')[0])>=firstUpdateDate &&
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Unfiled Intellectual Property (IP)</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have any IP yet to be filed? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('unfiledIP') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="unfiledIPYes">
                                                    <input type="radio" name="unfiledIP" id="unfiledIPYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.unfiledIP === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('unfiledIP') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="unfiledIPNo">
                                                    <input type="radio" name="unfiledIP" id="unfiledIPNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.unfiledIP === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sectionContent'>
                                    { renderUnfiledIPItems &&
                                    <> 
                                        <table className='contentTable'>
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Ownership</th>
                                                    <th>Date of First Use</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {unfiledIPCount>0 &&
                                            [...Array(unfiledIPCount)].map((element, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control' 
                                                            id={`unfiledIPItem${index+1}Type`} 
                                                            name={`unfiledIPItem${index+1}Type`}
                                                            placeholder='Type'
                                                            defaultValue={formData.get(`unfiledIPItem${index+1}Type`) || ''}
                                                            onChange={handleFormChange} 
                                                            disabled={disableAllFields} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='text' 
                                                            className='form-control' 
                                                            id={`unfiledIPItem${index+1}Ownership`} 
                                                            name={`unfiledIPItem${index+1}Ownership`} 
                                                            placeholder='Ownership'
                                                            defaultValue={formData.get(`unfiledIPItem${index+1}Ownership`) || ''} 
                                                            onChange={handleFormChange} 
                                                            disabled={disableAllFields} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className='form-control' 
                                                            type='date' 
                                                            id={`unfiledIPItem${index+1}DateOfFirstUse`} 
                                                            name={`unfiledIPItem${index+1}DateOfFirstUse`} 
                                                            placeholder='DateOfFirstUse'
                                                            defaultValue={formData.get(`unfiledIPItem${index+1}DateOfFirstUse`) || ''}
                                                            onChange={handleFormChange} 
                                                            disabled={disableAllFields} 
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                            </tbody>
                                        </table>
                                        <button className='incrementItem' type='button' onClick={handleAddUnfiledIPItemClick}>
                                            <svg width="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="27.5513" height="27.5513" rx="13.7756" fill="#1A5E9B"/>
                                                <path d="M13.5549 8.60986V18.5001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M8.60974 13.5549H18.4999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span>Add Unfiled IP</span>
                                        </button>
                                    </>
                                    }
                                    </div>
                                </div>
                                }
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Trade Secrets</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have Software Code, User Data/Customer List/vendor-supplier List, and Machine Learning models etc. that you are using for your business? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('companyTradeSecrets') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="companyTradeSecretsYes">
                                                    <input type="radio" name="companyTradeSecrets" id="companyTradeSecretsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.companyTradeSecrets === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('companyTradeSecrets') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="companyTradeSecretsNo">
                                                    <input type="radio" name="companyTradeSecrets" id="companyTradeSecretsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.companyTradeSecrets === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='companyTradeSecrets' name='companyTradeSecrets' onChange={handleFormChange} defaultValue={defaultFormData.companyTradeSecrets} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderCompanyTradeSecretItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='companyTradeSecretsDescription1'>Please describe the trade secrets <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='companyTradeSecretsDescription1'
                                                name='companyTradeSecretsDescription1'
                                                defaultValue={formData.get('companyTradeSecretsDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='companyTradeSecretsDescription2'>How long have you been using this data? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='companyTradeSecretsDescription2'
                                                name='companyTradeSecretsDescription2'
                                                defaultValue={formData.get('companyTradeSecretsDescription2')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                { (form.createdAt.split('T')[0])>=firstUpdateDate &&
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Concerns with Infringement</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have any concerns with infringement? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('concernsWithInfringement') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="concernsWithInfringementYes">
                                                    <input type="radio" name="concernsWithInfringement" id="concernsWithInfringementYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.concernsWithInfringement === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('concernsWithInfringement') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="concernsWithInfringementNo">
                                                    <input type="radio" name="concernsWithInfringement" id="concernsWithInfringementNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.concernsWithInfringement === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sectionContent'>
                                    { renderConcernsWithInfringementItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='concernsWithInfringementDescription1'>Please give some details <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='concernsWithInfringementDescription1'
                                                name='concernsWithInfringementDescription1'
                                                defaultValue={formData.get('concernsWithInfringementDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                }
                                { (form.createdAt.split('T')[0])>=firstUpdateDate &&
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Known Competitors</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Are you aware of any competitors? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('knownCompetitors') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="knownCompetitorsYes">
                                                    <input type="radio" name="knownCompetitors" id="knownCompetitorsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.knownCompetitors === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('knownCompetitors') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="knownCompetitorsNo">
                                                    <input type="radio" name="knownCompetitors" id="knownCompetitorsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.knownCompetitors === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sectionContent'>
                                    { renderKnownCompetitorsItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='knownCompetitorsDescription1'>Please give some details <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='knownCompetitorsDescription1'
                                                name='knownCompetitorsDescription1'
                                                defaultValue={formData.get('knownCompetitorsDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                }
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Custom Software</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have custom software that you developed? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('customSoftware') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="customSoftwareYes">
                                                    <input type="radio" name="customSoftware" id="customSoftwareYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.customSoftware === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('customSoftware') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="customSoftwareNo">
                                                    <input type="radio" name="customSoftware" id="customSoftwareNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.customSoftware === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='customSoftware' name='customSoftware' onChange={handleFormChange} defaultValue={defaultFormData.customSoftware} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderCustomSoftwareItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='customSoftwareDescription1'>Please describe the custom software <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='customSoftwareDescription1'
                                                name='customSoftwareDescription1'
                                                defaultValue={formData.get('customSoftwareDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='customSoftwareDescription2'>When was the first prototype software completed? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='customSoftwareDescription2'
                                                name='customSoftwareDescription2'
                                                defaultValue={formData.get('customSoftwareDescription2')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='customSoftwareDescription3'>Who developed it? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='customSoftwareDescription3'
                                                name='customSoftwareDescription3'
                                                defaultValue={formData.get('customSoftwareDescription3')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Publications</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have Publications (Whitepapers, blogs)? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('publications') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="publicationsYes">
                                                    <input type="radio" name="publications" id="publicationsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.publications === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('publications') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="publicationsNo">
                                                    <input type="radio" name="publications" id="publicationsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.publications === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='publications' name='publications' onChange={handleFormChange} defaultValue={defaultFormData.publications} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderPublicationItems &&
                                    <> 
                                        <table className='contentTable'>
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Authors</th>
                                                    <th>Date</th>
                                                    <th>Additional Info</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {publicationCount>0 &&
                                            [...Array(publicationCount)].map((element, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control' 
                                                            id={`publicationItem${index+1}Title`} 
                                                            name={`publicationItem${index+1}Title`}
                                                            placeholder='Title'
                                                            defaultValue={formData.get(`publicationItem${index+1}Title`) || ''}
                                                            onChange={handleFormChange} 
                                                            disabled={disableAllFields} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='text' 
                                                            className='form-control' 
                                                            id={`publicationItem${index+1}Authors`} 
                                                            name={`publicationItem${index+1}Authors`} 
                                                            placeholder='Authors'
                                                            defaultValue={formData.get(`publicationItem${index+1}Authors`) || ''} 
                                                            onChange={handleFormChange} 
                                                            disabled={disableAllFields} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className='form-control' 
                                                            type='date' 
                                                            id={`publicationItem${index+1}Date`} 
                                                            name={`publicationItem${index+1}Date`} 
                                                            placeholder='Date'
                                                            defaultValue={formData.get(`publicationItem${index+1}Date`) || ''}
                                                            onChange={handleFormChange} 
                                                            disabled={disableAllFields} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <textarea
                                                            className='form-control' 
                                                            rows={1}
                                                            id={`publicationItem${index+1}AdditionalInfo`} 
                                                            name={`publicationItem${index+1}AdditionalInfo`} 
                                                            placeholder='Additional Info'
                                                            defaultValue={formData.get(`publicationItem${index+1}AdditionalInfo`) || ''}
                                                            onChange={handleFormChange} 
                                                            disabled={disableAllFields} 
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                            }

                                            </tbody>
                                        </table>
                                        <button className='incrementItem' type='button' onClick={handleAddPublicationItemClick}>
                                            <svg width="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="27.5513" height="27.5513" rx="13.7756" fill="#1A5E9B"/>
                                                <path d="M13.5549 8.60986V18.5001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M8.60974 13.5549H18.4999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span>Add Publication</span>
                                        </button>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Licenses</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have Licenses for the Software(s) that you are using? (Licensed-in, Licensed-out) <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('licenses') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="licensesYes">
                                                    <input type="radio" name="licenses" id="licensesYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.licenses === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('licenses') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="licensesNo">
                                                    <input type="radio" name="licenses" id="licensesNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.licenses === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='licenses' name='licenses' onChange={handleFormChange} defaultValue={defaultFormData.licenses} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderLicenseItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='licensesDescription1'>Please provide some details of the licenses <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='licensesDescription1'
                                                name='licensesDescription1'
                                                defaultValue={formData.get('licensesDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='licensesDescription2'>How long have you been using these software for? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='licensesDescription2'
                                                name='licensesDescription2'
                                                defaultValue={formData.get('licensesDescription2')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='licensesDescription3'>Name of the individuals in the team who maintain the list? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='licensesDescription3'
                                                name='licensesDescription3'
                                                defaultValue={formData.get('licensesDescription3')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Company Brands</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Have you registered the name of the company? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('companyBrands') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="companyBrandsYes">
                                                    <input type="radio" name="companyBrands" id="companyBrandsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.companyBrands === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('companyBrands') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="companyBrandsNo">
                                                    <input type="radio" name="companyBrands" id="companyBrandsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.companyBrands === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='companyBrands' name='companyBrands' onChange={handleFormChange} defaultValue={defaultFormData.companyBrands} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderCompanyBrandItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='companyBrandsDescription1'>Where and when? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='companyBrandsDescription1'
                                                name='companyBrandsDescription1'
                                                defaultValue={formData.get('companyBrandsDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='companyBrandsDescription2'>Owner <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='companyBrandsDescription2'
                                                name='companyBrandsDescription2'
                                                defaultValue={formData.get('companyBrandsDescription2')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Product Brands</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Have you registered the name of other brands, companies or products? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('productBrands') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="productBrandsYes">
                                                    <input type="radio" name="productBrands" id="productBrandsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.productBrands === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('productBrands') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="productBrandsNo">
                                                    <input type="radio" name="productBrands" id="productBrandsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.productBrands === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='productBrands' name='productBrands' onChange={handleFormChange} defaultValue={defaultFormData.productBrands} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderProductBrandItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='productBrandsDescription1'>Where and when? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='productBrandsDescription1'
                                                name='productBrandsDescription1'
                                                defaultValue={formData.get('productBrandsDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='productBrandsDescription2'>Owner <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='productBrandsDescription2'
                                                name='productBrandsDescription2'
                                                defaultValue={formData.get('productBrandsDescription2')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Marketing/Advertising Material</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have Marketing/​Advertising Material/​Banners/​Flyers for the company or products? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('marketingMaterials') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="marketingMaterialsYes">
                                                    <input type="radio" name="marketingMaterials" id="marketingMaterialsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.marketingMaterials === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('marketingMaterials') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="marketingMaterialsNo">
                                                    <input type="radio" name="marketingMaterials" id="marketingMaterialsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.marketingMaterials === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='marketingMaterials' name='marketingMaterials' onChange={handleFormChange} defaultValue={defaultFormData.marketingMaterials} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderMarketingMaterialItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='marketingMaterialsDescription1'>Please provide some details <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='marketingMaterialsDescription1'
                                                name='marketingMaterialsDescription1'
                                                defaultValue={formData.get('marketingMaterialsDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='marketingMaterialsDescription2'>When the first Marketing/​Advertising Material was created? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='marketingMaterialsDescription2'
                                                name='marketingMaterialsDescription2'
                                                defaultValue={formData.get('marketingMaterialsDescription2')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='marketingMaterialsDescription3'>Owner <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='marketingMaterialsDescription3'
                                                name='marketingMaterialsDescription3'
                                                defaultValue={formData.get('marketingMaterialsDescription3')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Training Material/Product User Manuals</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have Training Material (new hire) /​Product User Manuals for the company or products? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('trainingMaterials') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="trainingMaterialsYes">
                                                    <input type="radio" name="trainingMaterials" id="trainingMaterialsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.trainingMaterials === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('trainingMaterials') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="trainingMaterialsNo">
                                                    <input type="radio" name="trainingMaterials" id="trainingMaterialsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.trainingMaterials === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='trainingMaterials' name='trainingMaterials' onChange={handleFormChange} defaultValue={defaultFormData.trainingMaterials} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderTrainingMaterialItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='trainingMaterialsDescription1'>Please provide some details <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='trainingMaterialsDescription1'
                                                name='trainingMaterialsDescription1'
                                                defaultValue={formData.get('trainingMaterialsDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='trainingMaterialsDescription2'>Estimated date When Training Material/​Product User Manuals was created? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='trainingMaterialsDescription2'
                                                name='trainingMaterialsDescription2'
                                                defaultValue={formData.get('trainingMaterialsDescription2')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='trainingMaterialsDescription3'>Who drafted those (in the company or outside)? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='trainingMaterialsDescription3'
                                                name='trainingMaterialsDescription3'
                                                defaultValue={formData.get('trainingMaterialsDescription3')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Domain Names</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <table className='contentTable'>
                                            <thead>
                                                <tr>
                                                    <th>Domain Name</th>
                                                    <th>Date of First Use</th>
                                                    <th>Expiry Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {domainNameItemsCount>0 &&
                                            [...Array(domainNameItemsCount)].map((element, index) => (
                                                <tr key={index}>
                                                    <td className='col-6'>
                                                        <input
                                                            type='text'
                                                            className='form-control' 
                                                            id={`domainNameItem${index+1}Name`} 
                                                            name={`domainNameItem${index+1}Name`}
                                                            placeholder={`domainName ${index+1}`} 
                                                            defaultValue={formData.get(`domainNameItem${index+1}Name`) || ''}
                                                            onChange={handleFormChange} 
                                                            disabled={disableAllFields} 
                                                        />
                                                    </td>
                                                    <td className='col-3'>
                                                        <input
                                                            type='date' 
                                                            className='form-control' 
                                                            id={`domainNameItem${index+1}FirstUse`} 
                                                            name={`domainNameItem${index+1}FirstUse`} 
                                                            defaultValue={formData.get(`domainNameItem${index+1}FirstUse`) || ''}
                                                            onChange={handleFormChange} 
                                                            disabled={disableAllFields} 
                                                        />
                                                    </td>
                                                    <td className='col-3'>
                                                        <input
                                                            className='form-control' 
                                                            type='date' 
                                                            id={`domainNameItem${index+1}ExpiryDate`} 
                                                            name={`domainNameItem${index+1}ExpiryDate`} 
                                                            defaultValue={formData.get(`domainNameItem${index+1}ExpiryDate`) || ''}
                                                            onChange={handleFormChange} 
                                                            disabled={disableAllFields} 
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        <button className='incrementItem' type='button' onClick={handleAddDomainNameItemClick}>
                                            <svg width="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="27.5513" height="27.5513" rx="13.7756" fill="#1A5E9B"/>
                                                <path d="M13.5549 8.60986V18.5001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M8.60974 13.5549H18.4999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span>Add Domain</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Product Designs/Innovative User interfaces</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have any Design patent? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('productDesigns') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="productDesignsYes">
                                                    <input type="radio" name="productDesigns" id="productDesignsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.productDesigns === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('productDesigns') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="productDesignsNo">
                                                    <input type="radio" name="productDesigns" id="productDesignsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.productDesigns === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='productDesigns' name='productDesigns' onChange={handleFormChange} defaultValue={defaultFormData.productDesigns} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderProductDesignItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='productDesignsDescription1'>Please provide some details <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='productDesignsDescription1'
                                                name='productDesignsDescription1'
                                                defaultValue={formData.get('productDesignsDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Contracts and Agreements</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have Employee Agreements, Non-disclosure Agreements, Non-Compete Agreements? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('contracts') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="contractsYes">
                                                    <input type="radio" name="contracts" id="contractsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.contracts === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('contracts') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="contractsNo">
                                                    <input type="radio" name="contracts" id="contractsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.contracts === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='contracts' name='contracts' onChange={handleFormChange} defaultValue={defaultFormData.contracts} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderContractItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='contractsDescription1'>Please provide some details <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='contractsDescription1'
                                                name='contractsDescription1'
                                                defaultValue={formData.get('contractsDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mb-3'>
                                            <div className='strongFont' htmlFor='contractsDescription2'>Do these agreements include IP clauses? <span className='requiredField'><strong>*</strong></span></div>
                                            <div className='radioButtonContainer'>
                                                <div className={`radioButton ${formData.get('contractsDescription2') === 'Y' ? 'active' : ''}`}>
                                                    <label htmlFor="contractsDescription2Yes">
                                                        <input type="radio" name="contractsDescription2" id="contractsDescription2Yes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.contractsDescription2 === 'Y'} required disabled={disableAllFields} />
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className={`radioButton ${formData.get('contractsDescription2') === 'N' ? 'active' : ''}`}>
                                                    <label htmlFor="contractsDescription2No">
                                                        <input type="radio" name="contractsDescription2" id="contractsDescription2No" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.contractsDescription2 === 'N'} required disabled={disableAllFields} />
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <select className='form-select w-25' id='contractsDescription2' name='contractsDescription2' onChange={handleFormChange} defaultValue={defaultFormData.contractsDescription2} required disabled={disableAllFields}>
                                                <option value="">Select Answer</option>
                                                <option value="Y">Yes</option>
                                                <option value="N">No</option>
                                            </select> */}
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='contractsDescription3'>Please provide some details <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='contractsDescription3'
                                                name='contractsDescription3'
                                                defaultValue={formData.get('contractsDescription3')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Departmental SOPs (Standard Operating Procedures)</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Do you have Departmental SOPs in place? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('departmentalStandardOperatingProcedures') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="departmentalStandardOperatingProceduresYes">
                                                    <input type="radio" name="departmentalStandardOperatingProcedures" id="departmentalStandardOperatingProceduresYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.departmentalStandardOperatingProcedures === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('departmentalStandardOperatingProcedures') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="departmentalStandardOperatingProceduresNo">
                                                    <input type="radio" name="departmentalStandardOperatingProcedures" id="departmentalStandardOperatingProceduresNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.departmentalStandardOperatingProcedures === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>  
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='departmentalStandardOperatingProcedures' name='departmentalStandardOperatingProcedures' onChange={handleFormChange} defaultValue={defaultFormData.departmentalStandardOperatingProcedures} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderDepartmentalStandardOperatingProcedureItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='departmentalStandardOperatingProceduresDescription1'>Please provide some details <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='departmentalStandardOperatingProceduresDescription1'
                                                name='departmentalStandardOperatingProceduresDescription1'
                                                defaultValue={formData.get('departmentalStandardOperatingProceduresDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='departmentalStandardOperatingProceduresDescription2'>How old are these SOPs? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='departmentalStandardOperatingProceduresDescription2'
                                                name='departmentalStandardOperatingProceduresDescription2'
                                                defaultValue={formData.get('departmentalStandardOperatingProceduresDescription2')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='departmentalStandardOperatingProceduresDescription3'>Who authored/​maintains (team) these? <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='departmentalStandardOperatingProceduresDescription3'
                                                name='departmentalStandardOperatingProceduresDescription3'
                                                defaultValue={formData.get('departmentalStandardOperatingProceduresDescription3')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <svg width="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="23" height="23" rx="5" fill="#1A5E9B" fillOpacity="0.21"/>
                                            <circle cx="11.5" cy="11.5" r="5.75" fill="white"/>
                                        </svg>
                                        <span>&nbsp;&nbsp;Certifications or compliance (Company/Product)</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Did your work require any certifications or compliances (safety)? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('certifications') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="certificationsYes">
                                                    <input type="radio" name="certifications" id="certificationsYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.certifications === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('certifications') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="certificationsNo">
                                                    <input type="radio" name="certifications" id="certificationsNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.certifications === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {/* <select className='form-select w-25' id='certifications' name='certifications' onChange={handleFormChange} defaultValue={defaultFormData.certifications} required disabled={disableAllFields}>
                                            <option value=''>Select Answer</option>
                                            <option value='Y'>Yes</option>
                                            <option value='N'>No</option>
                                        </select> */}
                                    </div>
                                    <div className='sectionContent'>
                                    { renderCertificationItems &&
                                    <>
                                        <div className='mergedLabel'>
                                            <label className='form-label' htmlFor='certificationsDescription1'>Please provide some details <span className='requiredField'><strong>*</strong></span></label>
                                            <textarea
                                                className='form-control'
                                                id='certificationsDescription1'
                                                name='certificationsDescription1'
                                                defaultValue={formData.get('certificationsDescription1')}
                                                onChange={handleFormChange}
                                                disabled={disableAllFields}
                                            />
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                            </div>
                            }
                            {tab === 'technicalSection2' &&
                            <div className='technicalSection2'>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                                <rect width="21" height="21" transform="translate(1 2)" fill="white"/>
                                                <path d="M13.25 3.75H6.25C5.78587 3.75 5.34075 3.93437 5.01256 4.26256C4.68437 4.59075 4.5 5.03587 4.5 5.5V19.5C4.5 19.9641 4.68437 20.4092 5.01256 20.7374C5.34075 21.0656 5.78587 21.25 6.25 21.25H16.75C17.2141 21.25 17.6592 21.0656 17.9874 20.7374C18.3156 20.4092 18.5 19.9641 18.5 19.5V9L13.25 3.75Z" fill="#1A5E9B" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M13.25 3.75V9H18.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M15 13.375H8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M15 16.875H8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M9.75 9.875H8.875H8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                        <span>Additional Documents</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div className='fileUploadContainer'>
                                            <div className='fileUploadContent'>
                                                { (defaultFormData.status==='new' || defaultFormData.status==='inProgress') &&
                                                <>
                                                <div className='fileUploadMessage'>
                                                    {/* <div>You can add multiple files by including them at the same time (Either when dragging and dropping, or after clicking 'Choose Files')</div> */}
                                                    <div className='errorText'>Files are not stored when the 'Save' button is used. Storage only occurs when the form is submitted.</div>
                                                </div>
                                                <div className='fileUploadInput'>
                                                    <input type="file" className="form-control w-50 mb-2" id="fileUploads" name="fileUploads" multiple  onChange={handleFormChange} />
                                                </div>
                                                </>
                                                }
                                                { (['submitted', 'complete', 'changesSubmitted', 'changesApproved'].includes(defaultFormData.status) && defaultFormData.fileUploads.length>0) &&
                                                <div className='fileUploadPreviewContainer p-2'>
                                                    <div className=''>
                                                        { defaultFormData.fileUploads.map((element, index) => (
                                                            <div key={index} className='fileUploadPreviewItem ps-1 mb-4'>
                                                                <a href={element} className='text-success'>{element}</a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                }
                                                { (['submitted', 'complete', 'changesSubmitted', 'changesApproved'].includes(defaultFormData.status) && defaultFormData.fileUploads[0]==='') &&
                                                <div className='fileUploadPreviewContainer p-2'>
                                                    <div className='text-success w-100 text-center'>No files uploaded</div>
                                                </div>
                                                }
                                                { fileUploadData &&
                                                <div className='fileUploadPreviewContainer'>
                                                    <div className='fileUploadPreviewContent'>
                                                        { fileUploadData.map((element, index) => (
                                                            <div key={index} className='fileUploadPreviewItem'>
                                                                {/* <img src={URL.createObjectURL(formData.fileUploads[index])} alt='preview' width={30} /> */}
                                                                <div className='text-success'>{element.name}</div>
                                                                <div onClick={handleRemoveFile} id={index} className='removeFile'>
                                                                    <svg width="15" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="16.2666" cy="16.2666" r="15.7666" fill="#E21D12" opacity={0.8} stroke="#EEEEEE"/>
                                                                        <path d="M21.1093 12L12 21.1093M12 12L21.1093 21.1093" stroke="#FFFFFF" strokeOpacity="1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                }
                                                { ['new', 'inProgress'].includes(form.status) &&
                                                <div className='fileUploadNotificationContainer'>
                                                    <p id='fileUploadNotification'className='fileUploadNotification text-center'>No files selected!</p>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='smallFont italicBlueColorFont blueBack'>Please include additional documents.</div>
                            </div>
                            }
                        </div>
                    ))}
                    </form>
                </div>
            </div>
            <div className='lowerSection'>
                <div className='tabSwitchNotificationContainer'>
                    <p id='tabSwitchNotification'className='tabSwitchNotification text-center'></p>
                </div>
                <div className='navigationButtons'>
                    <div className='backNext'>
                        <div className='navigationButtonContainer'>
                            <button className='navButton' type='button' id='backButton' onClick={handleBackClick} disabled={activeTabIndex>0 ? false:true}>
                                <svg width="10" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0249 17.725L1.7999 9.5L10.0249 1.275" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className='navigationButtonContainer'>
                            <button className='navButton' type='button' id='nextButton' onClick={handleNextClick} disabled={activeTabIndex !== tabs.length-1 ? false:true}>
                                <svg width="10" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.1499 17.725L9.3749 9.5L1.1499 1.275" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    { (form.status === 'new' || form.status === 'inProgress') &&
                    <>
                    <div type='button' id='saveButton' onClick={handleSave} className='saveButton'>
                        <button className='navButton' disabled={isSaving}>Save</button>
                    </div>
                    { activeTabIndex === tabs.length-1 &&
                    <div className='navigationButtonContainer'>
                        <button className='submitButton' type='button' id='submitButton' disabled={!isFormComplete || isSubmitting} data-bs-toggle="modal" data-bs-target={`#submitForm`} title='Submit Form'>Submit</button>
                        <div className="modal fade" id='submitForm' data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`submitFormHeader`} aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="modal-title" id={`submitFormHeader`}>
                                            Are you sure you want to submit {form.projectNumber}?
                                        </div>
                                    </div>
                                    <div className="modal-body errorText">
                                        <p>Please ensure all relevant files have been added before submission.</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="cancelButton" data-bs-dismiss="modal">Cancel</button>
                                        <button type='submit' form='intakeForm4' className="confirmButton" data-bs-dismiss="modal">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    </>
                    }
                    { ['changesSubmitted'].includes(form.status) &&
                    <div className='navigationButtonContainer'>
                        <button type='button' className="approveChangesButton" onClick={approveChanges}>Approve Changes</button>
                    </div>
                    }
                </div>
            </div>
            
        </div>
    )
}

