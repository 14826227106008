import './NavBar.css'
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../utilities/services/clients';
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter';
export default function NavBar({ client, updateClient, currentPage }) {
  const navigate = useNavigate()
  function handleLogOut(){
    logOut()
    updateClient(null)
    navigate('/')
  }

  const handleDropdownClick = (evt) => {
    // prevent dropdown from closing when clicked
    evt.stopPropagation()
  }

  return (
    <nav className='NavBar'>
      <div className='logo'>
        <img src="https://project-intake-assets.s3.ca-central-1.amazonaws.com/Logo.svg" alt="" />
      </div>
      <div className='dropdown profileDropDown dropstart'>
          <div className='profileDropDownButton' id="profileDropDownToggle" data-title='Profile' data-bs-toggle="dropdown" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
              <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
            </svg>
            {/* <span>&nbsp;&nbsp;</span>
            <svg width="12" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L8.5 8L16 1" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg> */}
          </div>
          <div className='dropdown-menu profileDropDown' aria-labelledby="profileDropDownToggle">
            <div className='userInfo' onClick={handleDropdownClick}>
              <div>
                <h6 className='m-0'><strong>{capitalizeFirstLetter(client.companyName)}</strong></h6>
                <p className='m-0 tertiaryText'>{client.companyEmail}</p>
              </div>
            </div>
            <div className='dropdown-item d-flex justify-content-between' onClick={handleLogOut}>
              <span>Log out</span>
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                < path d="M7.125 16.625H3.95833C3.53841 16.625 3.13568 16.4582 2.83875 16.1613C2.54181 15.8643 2.375 15.4616 2.375 15.0417V3.95833C2.375 3.53841 2.54181 3.13568 2.83875 2.83875C3.13568 2.54181 3.53841 2.375 3.95833 2.375H7.125M12.6667 13.4583L16.625 9.5M16.625 9.5L12.6667 5.54167M16.625 9.5H7.125" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
        </div>
    </nav>
  );
}