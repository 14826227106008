import { sendRequest } from "../request";
const BASE_URL = '/api/clients';


export function checkToken() {
    return sendRequest(`${BASE_URL}/check-token`);
}

export function login(credentials) {
    return sendRequest(`${BASE_URL}/login`, "POST", credentials, 'Unauthorized!')
}

export function getIntakeForms(clientID) {
    return sendRequest(`${BASE_URL}/${clientID}/intakeForms`)
}

export function getIntakeForm(clientID, formID) {
    return sendRequest(`${BASE_URL}/${clientID}/intakeForms/${formID}`)
}

export function updateIntakeForm(clientID, formID, form) {
    return sendRequest(`${BASE_URL}/${clientID}/intakeForms/${formID}/update`, "PUT", form, 'Unable to update intake form.')
}

// export function createEmailNotifications(clientID, formID, service) {
//     return sendRequest(`${BASE_URL}/${clientID}/intakeForms/${formID}/${service}/formEmailNotification`,"POST",{},'Unable to send email notifications')
// }

